import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RadioList,
	RequestFormPDFProps,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import {
	AddressCoordinates,
	CustomDate,
	WaterCounterAddresses
} from '@services/models'
import moment from 'moment'
import ConcernedInfosSection from '@components/ui/concernedInfosSection'
import {
	getAddressByCoordinates,
	getDefaultCadastralNumber
} from '@components/ui/addressForm/__index.utils'
import { formatStrapiText, getLabelFromList } from '@utils/methods'
import Text from '@components/ui/text'
import SelectInput from './inputs/select.inputs'
import DateFilterInput from './inputs/datefilter.input'
import { IWaterCounterAddressDto } from '@services/types'
import Notification from '@components/ui/notification'

type Classes = {
	coveredSearchPeriodContainer: string | undefined
	errorLabel: string
	rowSection: string | undefined
	button: string
	buttonContainer: string
	inputContainer: string
	dateAndTimeSection: string
	timeInputPadding: string
	adresseBlock: string
	checkbox2: string
	checkbox2Label: string
	checkbox2Container: string
	checkboxWrapper: string
	linkColor: string
}

const classes: Classes = makeClasses({
	button: {
		display: 'inline-block',
		padding: '10px 20px',
		fontSize: '13px',
		fontWeight: '500',
		color: Colors.white,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		MsTransition: 'all 0.35s ease',
		background: Colors.secondary,
		border: 'none',
		cursor: 'pointer',
		'&:hover': {
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2,
			color: Colors.white
		}
	},
	buttonContainer: {
		display: 'flex',
		width: 'fit-content',
		columnGap: '4px'
	},
	inputContainer: {
		marginBottom: '20px',
		display: 'block',
		width: '-webkit-fill-available'
	},
	dateAndTimeSection: {
		display: 'grid',
		alignItems: 'center',
		gridTemplateColumns: '27%27%',
		columnGap: '1%',
		[Breakpoints.maxWidth('md')]: {
			gridTemplateColumns: '49.5%49.5%'
		},
		[Breakpoints.maxWidth('sm')]: {
			gridTemplateColumns: '49.5%49.5%'
		}
	},
	coveredSearchPeriodContainer: {
		display: 'block',
		width: '40%'
	},
	rowSection: {
		display: 'flex',
		alignItems: 'end',
		columnGap: '1%',
		'& > div': {
			marginBottom: '20px'
		}
	},
	errorLabel: {
		color: Colors.errorRed,
		'& span': {
			color: Colors.errorRed + ' !important'
		}
	},
	timeInputPadding: {
		'& label': {
			paddingBottom: '1.8rem'
		}
	},
	adresseBlock: {
		border: `1px solid ${Colors.greyAlto}`,
		padding: '20px 25px',
		marginBottom: '10px'
	},
	checkbox2: {
		width: 'fit-content',
		position: 'absolute',
		verticalAlign: 'baseline',
		clip: 'rect(1px, 1px, 1px, 1px)'
	},
	checkbox2Label: {
		fontSize: '16px',
		padding: '0',
		color: Colors.lightBlack,
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: `1px solid ${Colors.lightGrey3}`,
			background: Colors.white,
			outline: 'none !important'
		},
		':after': {
			content: "''",
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: Colors.secondary,
			outline: 'none',
			opacity: '0',
			transition: 'all .15s ease-out'
		}
	},
	checkbox2Container: {
		'& input[value=true] ~ label:after': {
			opacity: '.99'
		}
	},
	checkboxWrapper: {
		display: 'block',
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '14px 12px'
	},
	linkColor: {
		a: { color: `${Colors.secondary} !important` }
	}
})

const SelfReliefWaterCounterForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	isSummaryStep,
	steps,
	toPrint,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	setDisableNextButton
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)
	const [isValidWaterCounterAddress, setIsValidWaterCounterAddress] =
		useState<boolean>()
	const [isVerifyingAddress, setIsVerifyingAddress] = useState<boolean>(false)
	const [addressAppartments, setAddressAppartments] = useState<RadioList[]>()

	//----------------------------------------------------------------
	//----------------------- INPUTS ---------------------------------
	//----------------------------------------------------------------

	const [isSameDeclarationAdresseInput, setIsSameDeclarationAdresseInput] =
		useState<RequestInputs>({
			name: 'sameAddressAsApplicant',
			label: pageAssets?.request_form_isSameAdresse_label,
			labelKey: 'request_form_isSameAdresse_label',
			value: answers.sameAddressAsApplicant?.toString() || 'false',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [appartmentListInput, setAppartmentListInput] = useState<RequestInputs>(
		{
			name: 'appartmentList',
			label: pageAssets?.request_form_isSameAdresse_label,
			labelKey: 'request_form_isSameAdresse_label',
			value: answers.appartmentList?.toString() || '[]',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		}
	)

	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [declareAddressInput, setDeclareAddressInput] = useState<RequestInputs>(
		{
			name: 'declareAddress',
			label: '',
			labelKey: '',
			value: answers.declareAddress || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		}
	)

	const [declareAppartmentInput, setDeclareAppartmentInput] =
		useState<RequestInputs>({
			name: 'declareAppartment',
			label: '',
			labelKey: '',
			value: answers.declareAppartment || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [buildingRegistrationNumberInput, setBuildingRegistrationNumberInput] =
		useState<RequestInputs>({
			name: 'buildingRegistrationNumber',
			label: '',
			labelKey: '',
			value: answers.buildingRegistrationNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})
	const [showDeclarationAdresseBlock, setShowDeclarationAdresseBlock] =
		useState<boolean>(answers.sameAddressAsApplicant == true ? false : true)

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const [ownerWaterCounterStatusInput, setOwnerWaterCounterStatusRequestInput] =
		useState<RequestInputs>({
			name: 'ownerWaterCounterStatus',
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_label,
			labelKey:
				'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_label',
			value: answers.ownerWaterCounterStatus?.toString() ?? '',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [showTheConditionalQuestions, setShowTheConditionalQuestions] =
		useState<boolean>(
			answers?.ownerWaterCounterStatus ==
				REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession ??
				ownerWaterCounterStatusInput.value ==
					REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession
		)

	const waterCounterStatusList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_inPossession_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_inPossession_label'
		},
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_notInPossession_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.notInPossession,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_notInPossession_label'
		},
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_broken_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.broken,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_broken_label'
		},
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_unReadable_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.unReadable,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_unReadable_label'
		},
		{
			label:
				pageAssets?.request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_unPlugged_label,
			value: REQUEST_CONFIGS_KEYS.waterCounterStatus.unPlugged,
			key: 'request_form_selfReliefWaterCounter_ownerWaterCounterStatusInput_unPlugged_label'
		}
	]

	const [counterReadingDateInput, setCounterReadingDateInput] =
		useState<RequestInputs>({
			name: 'counterReadingDate',
			label:
				pageAssets?.request_form_selfReliefWaterCounter_counterReadingDate_label,
			labelKey: 'request_form_selfReliefWaterCounter_counterReadingDate_label',
			value: answers.counterReadingDate ?? '',
			required:
				ownerWaterCounterStatusInput.value ==
				REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession,
			ref: useRef<HTMLInputElement>(null),
			extraParams: {
				type: 'date',
				min: moment().subtract(20, 'years').format('YYYY-MM-DD'),
				max: CustomDate.currentDate(),
				customErrorText: 'La date entrée est invalide'
			}
		})

	const [counterReadingValueInput, setCounterReadingValueInput] =
		useState<RequestInputs>({
			name: 'counterReadingValue',
			label:
				pageAssets?.request_form_selfReliefWaterCounter_counterReadingValue_label,
			labelKey: 'request_form_selfReliefWaterCounter_counterReadingValue_label',
			value: answers.counterReadingValue?.toString() || '',
			required:
				ownerWaterCounterStatusInput.value ==
				REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession,
			description:
				pageAssets?.request_form_selfReliefWaterCounter_counterReadingValue_description_label,
			ref: useRef<HTMLInputElement>(null)
		})

	const [counterSerialNumberInput, setCounterSerialNumberInput] =
		useState<RequestInputs>({
			name: 'counterSerialNumber',
			label:
				pageAssets?.request_form_selfReliefWaterCounter_counterSerialNumber_label,
			labelKey: 'request_form_selfReliefWaterCounter_counterSerialNumber_label',
			value: answers.counterSerialNumber?.toString() || '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})

	const [occupantNumberInput, setOccupantNumberInput] = useState<RequestInputs>(
		{
			name: 'occupantNumber',
			label:
				pageAssets?.request_form_selfReliefWaterCounter_occupantNumber_label,
			labelKey: 'request_form_selfReliefWaterCounter_occupantNumber_label',
			value: answers.occupantNumber?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		}
	)

	const [registerForEmailInput, setRegisterForEmailInput] =
		useState<RequestInputs>({
			name: 'registerForEmail',
			label:
				pageAssets?.request_form_selfReliefWaterCounter_registerForEmail_label,
			labelKey: 'request_form_selfReliefWaterCounter_registerForEmail_label',
			value: answers.registerForEmail?.toString() || 'true',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	//----------------------------------------------------------------
	//------------------- INPUTS SETTERS -----------------------------
	//----------------------------------------------------------------

	useEffect(() => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		if (!coordinates.validate()) {
			setIsAddressOutsideLaval(true)
		}
	}, [])

	// const onSelectIsSameDeclarationAdresseInput = (value) => {
	// 	setIsSameDeclarationAdresseInput({
	// 		...isSameDeclarationAdresseInput,
	// 		value: `${value}`
	// 	})

	// 	if (value == true) {
	// 		getCadastralAndMatriculeNumber(authUser?.profile?.address.coordinates!)
	// 	}

	// 	setTimeout(() => {
	// 		setShowDeclarationAdresseBlock(!value)
	// 	}, 100)

	// 	onFixError(
	// 		UpdateRequestInputsErrorsArray(errors, isSameDeclarationAdresseInput.name)
	// 	)
	// }

	const onSelectOwnerWaterCounterStatusInput = (value: string) => {
		setOwnerWaterCounterStatusRequestInput({
			...ownerWaterCounterStatusInput,
			value: `${value}`
		})

		setTimeout(() => {
			if (value == REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession) {
				setShowTheConditionalQuestions(true)
			} else {
				setShowTheConditionalQuestions(false)
			}

			setCounterReadingDateInput({
				...counterReadingDateInput,
				required: value === REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession
			})

			setCounterReadingValueInput({
				...counterReadingValueInput,
				required: value === REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession
			})
		}, 100)

		onFixError(
			errors.filter(
				(error) =>
					![
						ownerWaterCounterStatusInput.name,
						counterReadingDateInput.name,
						counterReadingValueInput.name
					].includes(error)
			)
		)
	}

	const onSelectCounterReadingDateInput = (value: string) => {
		if (Number(value.split('-', 2)[0]).toString().length > 4) {
			return
		}

		setCounterReadingDateInput({ ...counterReadingDateInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, counterReadingDateInput.name)
		)
	}

	const onSelectCounterReadingValueInput = (value: string) => {
		value = value.replace(/,/g, '.')
		let valueDecimals = value.match('[0-9]{1,15}(\\.[0-9]*)?')

		if (
			value.length > 19 ||
			(value.includes('.') && valueDecimals![1].length > 4)
		) {
			return
		}
		let newValue = value ? value.match('[0-9]{1,15}(\\.[0-9]*)?')![0] : ''

		setCounterReadingValueInput({
			...counterReadingValueInput,
			value: newValue
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, counterReadingValueInput.name)
		)
	}

	const onSelectCounterSerialNumberInput = (value: string) => {
		if ( value.length > 9) {
			return
		}

		setCounterSerialNumberInput({ ...counterSerialNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, counterSerialNumberInput.name)
		)
	}

	const onSelectOccupantNumberInput = (value: string) => {
		if (value.length > 5) {
			return
		}

		setOccupantNumberInput({ ...occupantNumberInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors, occupantNumberInput.name))
	}

	const onSelectRegisterForEmailInput = (value: string) => {
		setRegisterForEmailInput({ ...registerForEmailInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors, registerForEmailInput.name)
		)
	}

	//----------------------------------------------------------------
	//------------------- USE EFFECTS --------------------------------
	//----------------------------------------------------------------

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, appartmentListInput))
	}, [appartmentListInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, declareAddressInput))
	}, [declareAddressInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, declareAppartmentInput))
	}, [declareAppartmentInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, buildingBatchNumberInput))
	}, [buildingBatchNumberInput])

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs, buildingRegistrationNumberInput)
		)
	}, [buildingRegistrationNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, ownerWaterCounterStatusInput))
	}, [ownerWaterCounterStatusInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, counterReadingDateInput))
	}, [counterReadingDateInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, counterReadingValueInput))
	}, [counterReadingValueInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, counterSerialNumberInput))
	}, [counterSerialNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, occupantNumberInput))
	}, [occupantNumberInput])

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, registerForEmailInput))
	}, [registerForEmailInput])

	useEffect(() => {
		linkOpenNewTab()
	}, [showTheConditionalQuestions])

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: false,
			details_3: false,
			details_4: false,
			requiredDocument: false,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1]
		})
		const appartments = JSON.parse(appartmentListInput.value)

		if (appartments.length > 1) {
			setAddressAppartments(appartments)
		}
	}, [])

	//----------------------------------------------------------------
	//---------------- CONSTANTS AND FUNCTIONS -----------------------
	//----------------------------------------------------------------

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getCadastralAndMatriculeNumber = (coordinates: AddressCoordinates) => {
		setIsValidWaterCounterAddress(undefined)
		setIsVerifyingAddress(true)
		setDisableNextButton && setDisableNextButton(false)

		getAddressByCoordinates(coordinates).then((res) => {
			const properties = res?.data?.features?.[0].properties

			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: properties.cadastre
			})

			setBuildingRegistrationNumberInput({
				...buildingRegistrationNumberInput,
				value: properties.matricule
			})

			validateWaterCounterAddress(properties.address_id)
		})
	}

	const validateWaterCounterAddress = async (civic: string) => {
		const intance = new WaterCounterAddresses()

		const addresses: IWaterCounterAddressDto[] =
			await intance.getAddressesAsync(civic)

		if (addresses.length > 0) {
			// format and set appartments after address verification
			const appartmentList: string[] = addresses?.map(
				(address) => address.adresse_idf
			)

			if (appartmentList.length > 0) {
				addresses.sort((a, b) => {
					const numA = parseInt(a.no_civ_app_batiment, 10)
					const numB = parseInt(b.no_civ_app_batiment, 10)

					if (!isNaN(numA) && !isNaN(numB)) {
						return numA - numB
					} else if (!isNaN(numA)) {
						return -1 // Numeric values come first
					} else if (!isNaN(numB)) {
						return 1 // Non-numeric values come last
					} else {
						return a.no_civ_app_batiment.localeCompare(b.no_civ_app_batiment)
					}
				})

				const appartments = pageUtils.generateRadioListWithOriginals(
					addresses,
					'adresse_idf'
				)

				setAppartmentListInput({
					...appartmentListInput,
					value: JSON.stringify(appartments)
				})

				setAddressAppartments(appartments)
			}

			setIsVerifyingAddress(false)
			setIsValidWaterCounterAddress(true)
			setDisableNextButton && setDisableNextButton(true)
		} else {
			setIsVerifyingAddress(false)
			setIsValidWaterCounterAddress(false)
			setDisableNextButton && setDisableNextButton(false)
		}
	}

	const linkOpenNewTab = () => {
		const linksContainer = document.getElementsByClassName('link-redirect')

		Array.prototype.forEach.call(linksContainer, (container: HTMLElement) => {
			const links = container.getElementsByTagName('a')
			Array.prototype.forEach.call(links, (link) => {
				link.setAttribute('target', '_blank')
			})
		})
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_selfReliefWaterCounter_firstSubstep_title
								}
							</h4>
						</div>
						<div>
							<>
								<>
									<div>
										<strong>{pageAssets?.label_address}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers?.concernedAdresse}
										</span>
									</div>
								</>
								<>
									{answers?.concernedAppartement && (
										<div>
											<strong>{`${formatStrapiText(
												pageAssets?.label_apartment_office
											)}`}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.concernedAppartement}
											</span>
										</div>
									)}
								</>
							</>
							<strong>
								{pageAssets[ownerWaterCounterStatusInput.labelKey]}
							</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{
									pageAssets[
										waterCounterStatusList.find(
											(ownerWaterCounterStatus) =>
												ownerWaterCounterStatus.value ==
												answers.ownerWaterCounterStatus
										)?.key!
									]
								}
							</span>
						</div>
						{ownerWaterCounterStatusInput.value ==
							REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession && (
							<>
								<div>
									<strong>
										{pageAssets[counterReadingDateInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{pageUtils.getLocaleDate(answers?.counterReadingDate!)}
									</span>

									<strong>
										{pageAssets[counterReadingValueInput.labelKey]}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.counterReadingValue}
									</span>
								</div>
							</>
						)}

						{answers?.counterSerialNumber && (
							<>
								<strong>{pageAssets[counterSerialNumberInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.counterSerialNumber}
								</span>
							</>
						)}

						{occupantNumberInput.value != '' && (
							<>
								<strong>{pageAssets[occupantNumberInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.occupantNumber}
								</span>
							</>
						)}
					</>
				)}
			</section>
		)
	}

	const getNotificationText = () => {
		if (isVerifyingAddress) {
			return pageAssets?.request_form_waterCounter_please_wait_while_we_verify
		}

		return isValidWaterCounterAddress
			? pageAssets?.request_form_waterCounter_address_is_valid
			: pageAssets?.request_form_waterCounter_address_does_not_have
	}

	const onAddressInputChange = (address: string) => {
		if (address.trim() === '') {
			setAppartmentListInput({
				...appartmentListInput,
				value: '[]'
			})
			setAddressAppartments(undefined)
		}
	}

	const renderAddressInfo = () => {
		if (isValidWaterCounterAddress !== undefined || isVerifyingAddress) {
			return (
				<Notification
					type={
						isVerifyingAddress
							? 'info'
							: isValidWaterCounterAddress
							? 'info'
							: 'error'
					}
					showSpinner={isVerifyingAddress}
					text={getNotificationText()}
				/>
			)
		}
	}

	const onKeyPressIntegerInput = (event) => {
		const charCode = event.which ? event.which : event.keyCode
		const char = String.fromCharCode(charCode)
		const value = event.target.value + char

		if (!/^(?!0{2,})\d*$/.test(value)) {
			event.preventDefault()
		}
	}

	const onKeyPressNumberInput = (event) => {
		const charCode = event.which ? event.which : event.keyCode
		const char = String.fromCharCode(charCode)
		const value = event.target.value + char

		if (!/^\d*$/.test(value)) {
			event.preventDefault()
		}
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<div className={pageUtils.classes.heightAuto}>
					<section>
						<h5 className={pageUtils.classes.h4}>
							{
								pageAssets?.request_form_selfReliefWaterCounter_declarationAddressLabel
							}
						</h5>

						<div style={{ marginBottom: '20px' }}>
							{showDeclarationAdresseBlock == true ? (
								<>
									<ConcernedInfosSection
										errors={errors}
										inputs={inputs}
										onFixError={onFixError}
										onSetInputs={onSetInputs}
										isConcernedInfosRequired={
											`${isSameDeclarationAdresseInput.value}` == 'false'
										}
										requiredFields={[
											'concernedAdresse',
											'concernedAppartement'
										]}
										onGetCadastralAndMatriculeNumber={
											getCadastralAndMatriculeNumber
										}
										onAddressInputChange={onAddressInputChange}
										renderAddressInfo={renderAddressInfo}
										appartmentList={addressAppartments}
									/>
								</>
							) : (
								<div className={classes.adresseBlock}>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>
							)}
						</div>

						<SelectInput
							id={ownerWaterCounterStatusInput.name}
							label={ownerWaterCounterStatusInput.label}
							value={ownerWaterCounterStatusInput.value}
							list={waterCounterStatusList}
							required
							hasError={errors.includes(ownerWaterCounterStatusInput.name)}
							onChange={onSelectOwnerWaterCounterStatusInput}
							ref={
								ownerWaterCounterStatusInput.ref as RefObject<HTMLSelectElement>
							}
						/>

						{showTheConditionalQuestions && (
							<>
								<div className={classes.dateAndTimeSection}>
									<DateFilterInput
										id={counterReadingDateInput.name}
										label={counterReadingDateInput.label}
										value={counterReadingDateInput.value}
										onChange={onSelectCounterReadingDateInput}
										hasError={errors.includes(counterReadingDateInput.name)}
										required={
											ownerWaterCounterStatusInput.value ==
											REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession
										}
										classWrapper={classes.inputContainer}
										maxDate={new Date(moment().toDate())}
										dateFormat="dd/MM/yyyy"
									/>
								</div>

								<TextInput
									id={counterReadingValueInput.name}
									label={counterReadingValueInput.label}
									value={counterReadingValueInput.value}
									onChange={onSelectCounterReadingValueInput}
									placeholder="0.000"
									hasError={errors.includes(counterReadingValueInput.name)}
									required={
										ownerWaterCounterStatusInput.value ==
										REQUEST_CONFIGS_KEYS.waterCounterStatus.inPossession
									}
									ref={
										counterReadingValueInput.ref as RefObject<HTMLInputElement>
									}
									classWrapper={classes.inputContainer}
									description={
										<span
											className={joinClasses([
												classes.linkColor,
												'link-redirect'
											])}
										>
											<Text content={counterReadingValueInput.description} />
										</span>
									}
								/>
							</>
						)}
						{ownerWaterCounterStatusInput.value !==
							REQUEST_CONFIGS_KEYS.waterCounterStatus.notInPossession && (
							<TextInput
								id={counterSerialNumberInput.name}
								label={counterSerialNumberInput.label}
								value={counterSerialNumberInput.value}
								onChange={onSelectCounterSerialNumberInput}
								hasError={errors.includes(counterSerialNumberInput.name)}
								required={false}
								ref={
									counterSerialNumberInput.ref as RefObject<HTMLInputElement>
								}
								classWrapper={classes.inputContainer}
								description={
									<span
										className={joinClasses([
											classes.linkColor,
											'link-redirect'
										])}
									>
										<Text
											content={
												pageAssets?.request_form_selfReliefWaterCounter_whereIsCounter_label
											}
										/>
									</span>
								}
								onKeyPress={onKeyPressNumberInput}
							/>
						)}

						<TextInput
							id={occupantNumberInput.name}
							label={occupantNumberInput.label}
							value={occupantNumberInput.value}
							onChange={onSelectOccupantNumberInput}
							hasError={errors.includes(occupantNumberInput.name)}
							required={occupantNumberInput.required}
							ref={occupantNumberInput.ref as RefObject<HTMLInputElement>}
							classWrapper={classes.inputContainer}
							description={occupantNumberInput.description}
							type="number"
							onKeyPress={onKeyPressIntegerInput}
						/>
					</section>
				</div>
			)}
		</>
	)
}

export default SelfReliefWaterCounterForm
