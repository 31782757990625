import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	PesticideCertificates,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList,
	VehicleInformation
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { AddressCoordinates, CustomDate } from '@services/models'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import * as formUtils from '@pages/auth/__index.utils'
import { PHONE_NUMBER_REGEX } from '@pages/auth/profile/__index.utils'
import MaskedTextInput from '@components/requestForms/inputs/textWithValidation.inputs'
import Text from '@components/ui/text'
import {
	closeIcon,
	enceinte1,
	enceinte2,
	enceinte3,
	enceinte4,
	enceinte5,
	poolForme1,
	poolForme2,
	poolForme3
} from '@images/icons'
import RequestFiles from '@components/ui/requestFiles'
import DateFilterInput from './inputs/datefilter.input'
import RadioInput from './inputs/radio.input'
import AddressInfoSection from '@components/ui/addressInfosSection'
import Notification from '@components/ui/notification'
import SelectInput from './inputs/select.inputs'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'

const SwimmingPoolSpaPermitForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	setDisableNextButton,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	setStepsList
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
		checkboxWrapper: string
		adresseBlock: string
		h5: string
		greyBackgroundBlock: string
		detailscontainer: string
		subContent: string
		reducedMarginBottom: string
		image: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},

		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},

		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			marginTop: '-15px',
			marginBottom: '15px',
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		},
		checkboxWrapper: {
			display: 'block',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '14px 12px'
		},

		adresseBlock: {
			border: `1px solid ${Colors.greyAlto}`,
			padding: '20px 25px',
			marginBottom: '10px'
		},
		h5: {
			fontSize: '20px',
			fontWeight: '600',
			margin: '15px 0 10px 0'
		},
		greyBackgroundBlock: {
			background: Colors.darkWhite,
			padding: '15px 30px'
		},
		detailscontainer: {
			display: 'grid',
			alignItems: 'start',
			gridTemplateColumns: '50%43%',
			columnGap: '7%',
			[Breakpoints.maxWidth('lg')]: {
				gridTemplateColumns: '45%46%',
				columnGap: '12%'
			},
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '100%',
				'& img': {
					marginTop: '15px !important',
					width: '80% !important'
				}
			},
			[Breakpoints.maxWidth('sm')]: {
				'& img': {
					width: '100% !important'
				}
			},
			lineHeight: '1.62em',
			// fontSize: '16px',
			color: Colors.lightBlack,
			'& img': {
				width: '100%',
				verticalAlign: 'middle',
				margin: 'auto'
			},
			'& ul': {
				paddingLeft: '18px',
				margin: 0,
				'& li::marker': {
					color: Colors.secondary
				}
			}
		},
		subContent: {
			display: 'grid',
			columnGap: '10px',
			gridTemplateColumns: '66%44%',
			alignItems: 'end',
			[Breakpoints.maxWidth('lg')]: {
				gridTemplateColumns: '60%20%'
			},
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '55%20%'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '48%20%'
			}
		},
		reducedMarginBottom: {
			marginBottom: '18px'
		},
		image: {
			[Breakpoints.maxWidth('md')]: {
				width: '35%'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const [showAdresseBlock, setShowAdresseBlock] = useState<boolean>(
		answers?.sameAddressAsApplicant?.toString() == 'false' ? true : false
	)

	//----------------------------------------------------------------
	//--------------------- DETAILS 1 INPUTS -------------------------
	//----------------------------------------------------------------

	// ************* Type de demande ***********

	// Que souhaitez-vous faire?
	const [whatToDoInput, setWhatToDoInput] = useState<RequestInputs>({
		name: 'whatToDo',
		label: pageAssets?.request_form_what_would_you_like_to_do,
		labelKey: 'request_form_what_would_you_like_to_do',
		value: answers?.whatToDo?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const radioListWhatToDoSwimmingPoolSpaPermit: RadioList[] = [
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_request_installation,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoSwimmingPoolSpaPermit.request_installation,
			key: 'request_form_swimmingPoolSpaPermit_request_installation'
		},
		{
			label: pageAssets?.request_form_swimmingPoolSpaPermit_request_upgrading,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoSwimmingPoolSpaPermit.request_upgrading,
			key: 'request_form_swimmingPoolSpaPermit_request_upgrading'
		}
	]

	// Vous faites cette demande en tant que:
	const [requestAsInput, setRequestAsInput] = useState<RequestInputs>({
		name: 'requestAs',
		label: pageAssets?.request_form_request_as,
		labelKey: 'request_form_request_as',
		value: answers?.requestAs?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}
	]

	// ************* Informations relatives à l'entreprise ***********

	// Nom de l'entreprise
	const [companyNameInput, setCompanyNameInput] = useState<RequestInputs>({
		name: 'companyName',
		label:
			pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
		labelKey:
			'request_form_annualRegistrationCertificateForContractors_companyName_label',
		value: answers.companyName || '',
		required:
			answers.requestAs?.toString() ==
			REQUEST_CONFIGS_KEYS.requestAs.entreprise,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Nom et prénom du représentant
	const [representativeNameInput, setRepresentativeNameInput] =
		useState<RequestInputs>({
			name: 'representativeName',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_representativeName_label,
			labelKey: 'request_form_swimmingPoolSpaPermit_representativeName_label',
			value: answers.representativeName || '',
			required:
				answers.requestAs?.toString() ==
				REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	//----------------------------------------------------------------
	//-------------------- SubStep DETAILS_2 INPUTS ------------------
	//----------------------------------------------------------------

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const [isSameLocationInput, setIsSameLocationInput] = useState<RequestInputs>(
		{
			name: 'sameAddressAsApplicant',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_isSameLocation,
			labelKey: 'request_form_swimmingPoolSpaPermit_isSameLocation',
			value: answers?.sameAddressAsApplicant?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	const radioListAddressOption: RadioList[] = [
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.address,
			key: 'request_form_addressInfoSection_addressOption_firstOption_label'
		},
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.cadastralNumber,
			key: 'request_form_addressInfoSection_addressOption_secondOption_label'
		}
	]

	const [isLocationOwnedInput, setIsLocationOwnedInput] =
		useState<RequestInputs>({
			name: 'isLocationOwned',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_isLocationOwned,
			labelKey: 'request_form_swimmingPoolSpaPermit_isLocationOwned',
			value: answers?.isLocationOwned?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	//****** Owner inputs ******

	const [ownerFirstNameInput, setOwnerFirstNameInput] = useState<RequestInputs>(
		{
			name: 'ownerFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.ownerFirstName || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)
	const [ownerLastNameInput, setOwnerLastNameInput] = useState<RequestInputs>({
		name: 'ownerLastName',
		label: pageAssets?.label_lastName,
		labelKey: 'label_lastName',
		value: answers.ownerLastName || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerEmailInput, setOwnerEmailInput] = useState<RequestInputs>({
		name: 'ownerEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.ownerEmail || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerPhoneNumberInput, setOwnerPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'ownerPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.ownerPhoneNumber || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Procuration du propriétaire
	// Owner's power of attorney
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_ownerPowerOfAttorney,
		labelKey: 'request_form_ownerPowerOfAttorney',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		description: '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		category: requestCategories.proxy
	})

	//****** Fin Owner inputs  ******

	const [isPropertyJointOwnershipInput, setIsPropertyJointOwnershipInput] =
		useState<RequestInputs>({
			name: 'isPropertyJointOwnership',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_isPropertyJointOwnership,
			labelKey: 'request_form_swimmingPoolSpaPermit_isPropertyJointOwnership',
			value: answers?.isPropertyJointOwnership?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Autorisation des administrateurs
	const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
		name: 'secondInput',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_admin_permission,
		labelKey: 'request_form_swimmingPoolSpaPermit_admin_permission',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondInput')?.files || []
		),
		required:
			answers?.isPropertyJointOwnership?.toString() == radioListYesNo[0].value,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		category: requestCategories.other
	})

	const [
		isPropertyLocatedOnWaterfrontInput,
		setIsPropertyLocatedOnWaterfrontInput
	] = useState<RequestInputs>({
		name: 'isPropertyLocatedOnWaterfront',
		label:
			pageAssets?.request_form_swimmingPoolSpaPermit_isPropertyLocatedOnWaterfront,
		labelKey:
			'request_form_swimmingPoolSpaPermit_isPropertyLocatedOnWaterfront',
		value: answers?.isPropertyLocatedOnWaterfront?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_3 INPUTS -------------------
	//---------------------------------------------------------------

	// Quel est le type d'installation?
	const [installationTypeInput, setInstallationTypeInput] =
		useState<RequestInputs>({
			name: 'installationType',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_installationType,
			labelKey: 'request_form_swimmingPoolSpaPermit_installationType',
			value: answers?.installationType?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const radioListInstallationType: RadioList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_installationType_option1,
			value:
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.firstOption,
			key: 'request_form_swimmingPoolSpaPermit_installationType_option1'
		},
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_installationType_option2,
			value:
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.secondOption,
			key: 'request_form_swimmingPoolSpaPermit_installationType_option2'
		},
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_installationType_option3,
			value:
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.thirdOption,
			key: 'request_form_swimmingPoolSpaPermit_installationType_option3'
		},
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_installationType_option4,
			value:
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.fourthOption,
			key: 'request_form_swimmingPoolSpaPermit_installationType_option4'
		}
	]

	// Est-ce un bain à remous (spa) de 2000 litres ou plus?
	const [isSpaLitersConditionInput, setIsSpaLitersConditionInput] =
		useState<RequestInputs>({
			name: 'isSpaLitersCondition',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_isSpaLitersCondition,
			labelKey: 'request_form_swimmingPoolSpaPermit_isSpaLitersCondition',
			value: answers?.isSpaLitersCondition?.toString() || '',
			required:
				answers?.installationType?.toString() ==
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.fourthOption,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const POOL_SHAPE_REQUIRED_OPTIONS = [
		REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.firstOption,
		REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.secondOption,
		REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.thirdOption
	]

	// Quelle est la forme la piscine?
	const [poolShapeInput, setPoolShapeInput] = useState<RequestInputs>({
		name: 'poolShape',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_poolShape,
		labelKey: 'request_form_swimmingPoolSpaPermit_poolShape',
		value: answers?.poolShape?.toString() || '',
		required: POOL_SHAPE_REQUIRED_OPTIONS.includes(
			answers?.installationType?.toString() || ''
		),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const radioListPoolShape: RadioList[] = [
		{
			label: pageAssets?.request_form_swimmingPoolSpaPermit_poolShape_option1,
			value: REQUEST_CONFIGS_KEYS.poolShape.firstOption,
			key: 'request_form_swimmingPoolSpaPermit_poolShape_option1'
		},
		{
			label: pageAssets?.request_form_swimmingPoolSpaPermit_poolShape_option2,
			value: REQUEST_CONFIGS_KEYS.poolShape.secondOption,
			key: 'request_form_swimmingPoolSpaPermit_poolShape_option2'
		},
		{
			label: pageAssets?.request_form_swimmingPoolSpaPermit_poolShape_option3,
			value: REQUEST_CONFIGS_KEYS.poolShape.thirdOption,
			key: 'request_form_swimmingPoolSpaPermit_poolShape_option3'
		}
	]

	const getPoolShapeImage = (value) => {
		switch (value) {
			case REQUEST_CONFIGS_KEYS.poolShape.firstOption:
				return poolForme1
			case REQUEST_CONFIGS_KEYS.poolShape.secondOption:
				return poolForme2
			case REQUEST_CONFIGS_KEYS.poolShape.thirdOption:
				return poolForme3
		}
	}

	const radioListMeasurementUnit: RadioList[] = [
		{
			label: pageAssets?.request_form_meters,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			key: 'request_form_meters'
		},
		{
			label: pageAssets?.request_form_foot,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			key: 'label_kg'
		}
	]

	// Diamètre de la piscine Pool
	const [poolDiameterInput, setPoolDiameterInput] = useState<RequestInputs>({
		name: 'poolDiameter',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_poolDiameter,
		labelKey: 'request_form_swimmingPoolSpaPermit_poolDiameter',
		value: answers?.poolDiameter?.toString() || '',
		required:
			answers?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value &&
			answers.poolShape?.toString() ==
				REQUEST_CONFIGS_KEYS.poolShape.firstOption,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const [poolDiameterUnitInput, setPoolDiameterUnitInput] =
		useState<RequestInputs>({
			name: 'poolDiameterUnit',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_poolDiameterUnit,
			labelKey: 'request_form_swimmingPoolSpaPermit_poolDiameterUnit',
			value:
				answers?.poolDiameterUnit?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			required:
				answers?.whatToDo?.toString() ==
					radioListWhatToDoSwimmingPoolSpaPermit[0].value &&
				answers.poolShape?.toString() ==
					REQUEST_CONFIGS_KEYS.poolShape.firstOption,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Hauteur de la piscine
	const [poolHeightInput, setPoolHeightInput] = useState<RequestInputs>({
		name: 'poolHeight',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_poolHeight,
		labelKey: 'request_form_swimmingPoolSpaPermit_poolHeight',
		value: answers?.poolHeight?.toString() || '',
		required:
			answers?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value &&
			answers.installationType?.toString() ==
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.firstOption,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const [poolHeightUnitInput, setPoolHeightUnitInput] = useState<RequestInputs>(
		{
			name: 'poolHeightUnit',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_poolHeightUnit,
			labelKey: 'request_form_swimmingPoolSpaPermit_poolHeightUnit',
			value:
				answers?.poolHeightUnit?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			required:
				answers?.whatToDo?.toString() ==
					radioListWhatToDoSwimmingPoolSpaPermit[0].value &&
				answers.installationType?.toString() ==
					REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
						.firstOption,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		}
	)

	// largeur de la piscine
	const [poolWidthInput, setPoolWidthInput] = useState<RequestInputs>({
		name: 'poolWidth',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_poolWidth,
		labelKey: 'request_form_swimmingPoolSpaPermit_poolWidth',
		value: answers?.poolWidth?.toString() || '',
		required: [
			REQUEST_CONFIGS_KEYS.poolShape.secondOption,
			REQUEST_CONFIGS_KEYS.poolShape.thirdOption
		].includes(answers.poolShape?.toString() || ''),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const [poolWidthUnitInput, setPoolWidthUnitInput] = useState<RequestInputs>({
		name: 'poolWidthUnit',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_poolWidthUnit,
		labelKey: 'request_form_swimmingPoolSpaPermit_poolWidthUnit',
		value:
			answers?.poolWidthUnit?.toString() ||
			REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
		required: [
			REQUEST_CONFIGS_KEYS.poolShape.secondOption,
			REQUEST_CONFIGS_KEYS.poolShape.thirdOption
		].includes(answers.poolShape?.toString() || ''),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	// longeur de la piscine
	const [poolLengthInput, setPoolLengthInput] = useState<RequestInputs>({
		name: 'poolLength',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_poolLength,
		labelKey: 'request_form_swimmingPoolSpaPermit_poolLength',
		value: answers?.poolLength?.toString() || '',
		required: [
			REQUEST_CONFIGS_KEYS.poolShape.secondOption,
			REQUEST_CONFIGS_KEYS.poolShape.thirdOption
		].includes(answers.poolShape?.toString() || ''),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const [poolLengthUnitInput, setPoolLengthUnitInput] = useState<RequestInputs>(
		{
			name: 'poolLengthUnit',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_poolLengthUnit,
			labelKey: 'request_form_swimmingPoolSpaPermit_poolLengthUnit',
			value:
				answers?.poolLengthUnit?.toString() ||
				REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			required: [
				REQUEST_CONFIGS_KEYS.poolShape.secondOption,
				REQUEST_CONFIGS_KEYS.poolShape.thirdOption
			].includes(answers.poolShape?.toString() || ''),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		}
	)

	//-------------------- Installation et travaux INPUTS ------------------------
	// Date d'installation prévue
	const [expectedInstallDateInput, setExpectedInstallDateInput] =
		useState<RequestInputs>({
			name: 'expectedInstallDate',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_expectedInstallDate,
			labelKey: 'request_form_swimmingPoolSpaPermit_expectedInstallDate',
			value: answers?.expectedInstallDate?.toString() || '',
			required:
				answers?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value
					? true
					: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Coût des travaux d'installation
	const [installationCostInput, setInstallationCostInput] =
		useState<RequestInputs>({
			name: 'installationCost',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_installationCost,
			labelKey: 'request_form_swimmingPoolSpaPermit_installationCost',
			value: answers?.installationCost?.toString() || '',
			required:
				answers?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value
					? true
					: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
			description:
				pageAssets?.request_form_swimmingPoolSpaPermit_installationCost_description
		})

	// Is a tree(s) cut required?
	const [isTreeCutRequiredInput, setIsTreeCutRequiredInput] =
		useState<RequestInputs>({
			name: 'isTreeCutRequired',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_isTreeCutRequired,
			labelKey: 'request_form_swimmingPoolSpaPermit_isTreeCutRequired',
			value: answers?.isTreeCutRequired?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
			description:
				pageAssets?.request_form_swimmingPoolSpaPermit_isTreeCutRequired_description
		})

	//-------------------- Informations du vendeur de la piscine INPUTS ------------------------
	// Nom de l'entreprise
	const [companyPoolNameInput, setCompanyPoolNameInput] =
		useState<RequestInputs>({
			name: 'companyPoolName',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_companyPoolName_label,
			labelKey: 'request_form_swimmingPoolSpaPermit_companyPoolName_label',
			value: answers.companyPoolName || '',
			required:
				answers?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value
					? true
					: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	// Adresse
	const [companyAdresseInput, setCompanyAdresseInput] = useState<RequestInputs>(
		{
			name: 'companyAdresse',
			label: pageAssets?.label_address,
			labelKey: 'label_address',
			value: answers.companyAdresse || '',
			required:
				answers?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value
					? true
					: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		}
	)
	const [companyCityInput, setCompanyCityInput] = useState<RequestInputs>({
		name: 'companyCity',
		label: pageAssets?.label_city,
		labelKey: 'label_city',
		value: answers.companyCity || '',
		required:
			answers?.whatToDo?.toString() ==
			radioListWhatToDoSwimmingPoolSpaPermit[0].value
				? true
				: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const [companyPostalCodeInput, setCompanyPostalCodeInput] =
		useState<RequestInputs>({
			name: 'companyPostalCode',
			label: pageAssets?.label_postalCode,
			labelKey: 'label_postalCode',
			value: answers.companyPostalCode || '',
			required:
				answers?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value
					? true
					: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const [companyEmailInput, setCompanyEmailInput] = useState<RequestInputs>({
		name: 'companyEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.companyEmail || '',
		required:
			answers?.whatToDo?.toString() ==
			radioListWhatToDoSwimmingPoolSpaPermit[0].value
				? true
				: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const [companyPhoneNumberInput, setCompanyPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'companyPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.companyPhoneNumber || '',
			required:
				answers?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value
					? true
					: false,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_4 INPUTS -------------------
	//---------------------------------------------------------------

	// Sélectionnez le type d'enceinte de protection
	// type of protectiveEnclosureType
	const [protectiveEnclosureTypeInput, setProtectiveEnclosureTypeInput] =
		useState<RequestInputs>({
			name: 'protectiveEnclosureType',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_protectiveEnclosureType,
			labelKey: 'request_form_swimmingPoolSpaPermit_protectiveEnclosureType',
			value: answers?.protectiveEnclosureType?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const diplayOptionDetails = (image) => {
		return <img className={classes.image} src={image} />
	}

	const protectiveEnclosureTypeList: RadioList[] = [
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_firstOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.firstOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_firstOption',
			details: diplayOptionDetails(enceinte1)
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_secondOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.secondOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_secondOption',
			details: diplayOptionDetails(enceinte2)
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_thirdOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.thirdOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_thirdOption',
			details: diplayOptionDetails(enceinte3)
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_fourthOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.fourthOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_fourthOption',
			details: diplayOptionDetails(enceinte4)
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_fifthOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.fifthOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_fifthOption',
			details: diplayOptionDetails(enceinte5)
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_sixthOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.sixthOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_sixthOption',
			details: <></>
		}
	]

	// Date d'achat de l'enceinte de protection
	const [
		protectiveEnclosurePurchaseDateInput,
		setProtectiveEnclosurePurchaseDateInput
	] = useState<RequestInputs>({
		name: 'protectiveEnclosurePurchaseDate',
		label:
			pageAssets?.request_form_swimmingPoolSpaPermit_protectiveEnclosurePurchaseDate,
		labelKey:
			'request_form_swimmingPoolSpaPermit_protectiveEnclosurePurchaseDate',
		value: answers?.protectiveEnclosurePurchaseDate?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Non applicable / J'utilise une enceinte de protection déjà installée
	const [
		isProtectiveEnclosureAlreadyInstalledInput,
		setIsProtectiveEnclosureAlreadyInstalledInput
	] = useState<RequestInputs>({
		name: 'isProtectiveEnclosureAlreadyInstalled',
		label:
			pageAssets?.request_form_swimmingPoolSpaPermit_isProtectiveEnclosureAlreadyInstalled,
		labelKey:
			'request_form_swimmingPoolSpaPermit_isProtectiveEnclosureAlreadyInstalled',
		value:
			answers?.isProtectiveEnclosureAlreadyInstalled?.toString() || 'false',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Y a-t-il une ou des portes ou des fenêtres qui se trouvent à l'intérieur l'enceinte de protection?
	const [
		isProtectiveEnclosureHasWindowsInput,
		setIsProtectiveEnclosureHasWindowsInput
	] = useState<RequestInputs>({
		name: 'isProtectiveEnclosureHasWindows',
		label:
			pageAssets?.request_form_swimmingPoolSpaPermit_isProtectiveEnclosureHasWindows,
		labelKey:
			'request_form_swimmingPoolSpaPermit_isProtectiveEnclosureHasWindows',
		value: answers?.isProtectiveEnclosureHasWindows?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	const [dummyOptionalInput, setDummyOptionalInput] = useState<RequestInputs>({
		name: 'dummyOptional',
		label: '',
		labelKey: '',
		value: '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})
	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// Certificat de localisation
	const [thirdQuestion, setThirdQuestion] = useState<RequestInputFiles>({
		name: 'thirdInput',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_thirdInput_label,
		labelKey: 'request_form_swimmingPoolSpaPermit_thirdInput_label',
		description:
			pageAssets?.request_form_swimmingPoolSpaPermit_thirdInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'thirdInput')?.files || []
		),
		required: true,
		numberMaxFiles: 10,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'implantation
	const [fourthQuestion, setFourthQuestion] = useState<RequestInputFiles>({
		name: 'fourthInput',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_fourthInput_label,
		labelKey: 'request_form_swimmingPoolSpaPermit_fourthInput_label',
		description:
			pageAssets?.request_form_swimmingPoolSpaPermit_fourthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fourthInput')?.files || []
		),
		required: true,
		numberMaxFiles: 10,
		category: requestCategories.technicalPlan,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Photos de la cour arrière, du balcon et du mur arrière montrant les ouvertures (portes et fenêtres)
	const [fifthQuestion, setFifthQuestion] = useState<RequestInputFiles>({
		name: 'fifthInput',
		label: pageAssets?.request_form_swimmingPoolSpaPermit_fifthInput_label,
		labelKey: 'request_form_swimmingPoolSpaPermit_fifthInput_label',
		description:
			pageAssets?.request_form_swimmingPoolSpaPermit_fifthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fifthInput')?.files || []
		),
		required: true,
		numberMaxFiles: 10,
		category: requestCategories.photographicEvidence,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Autres documents
	const [sixthQuestion, setSixthQuestion] = useState<RequestInputFiles>({
		name: 'sixthInput',
		label: pageAssets?.request_form_temporaryUseOfPesticide_other_documents,
		labelKey: 'request_form_temporaryUseOfPesticide_other_documents',
		description:
			pageAssets?.request_form_swimmingPoolSpaPermit_sixthInput_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'sixthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 10,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// ------------------- DETAILS INPUTS SETTERS --------------------

	const setRequiredProtectionOrInstallationInputs = (value) => {
		setExpectedInstallDateInput({
			...expectedInstallDateInput,
			required: value
		})
		setInstallationCostInput({ ...installationCostInput, required: value })
		setCompanyPoolNameInput({ ...companyPoolNameInput, required: value })
		setCompanyAdresseInput({ ...companyAdresseInput, required: value })
		setCompanyCityInput({ ...companyCityInput, required: value })
		setCompanyPostalCodeInput({ ...companyPostalCodeInput, required: value })
		setCompanyEmailInput({ ...companyEmailInput, required: value })
		setCompanyPhoneNumberInput({ ...companyPhoneNumberInput, required: value })
	}

	const onSetWhatToDoInput = (value: string) => {
		setWhatToDoInput({ ...whatToDoInput, value })

		if (value == radioListWhatToDoSwimmingPoolSpaPermit[0].value) {
			setRequiredProtectionOrInstallationInputs(true)
		} else {
			setRequiredProtectionOrInstallationInputs(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						whatToDoInput.name,
						expectedInstallDateInput.name,
						installationCostInput.name,
						companyPoolNameInput.name,
						companyAdresseInput.name,
						companyCityInput.name,
						companyPostalCodeInput.name,
						companyEmailInput.name,
						companyPhoneNumberInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, whatToDoInput))
	}, [whatToDoInput])
	//------------------------------------------------------------

	const onSetRequestAsInput = (value: string) => {
		setRequestAsInput({ ...requestAsInput, value })

		if (value == REQUEST_CONFIGS_KEYS.requestAs.entreprise) {
			setCompanyNameInput({ ...companyNameInput, required: true })
			setRepresentativeNameInput({ ...representativeNameInput, required: true })
		} else {
			setCompanyNameInput({ ...companyNameInput, required: false })
			setRepresentativeNameInput({
				...representativeNameInput,
				required: false
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						requestAsInput.name,
						companyNameInput.name,
						representativeNameInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, requestAsInput))
	}, [requestAsInput])
	//------------------------------------------------------------

	const onSetCompanyNameInput = (value: string) => {
		setCompanyNameInput({ ...companyNameInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, companyNameInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, companyNameInput))
	}, [companyNameInput])

	//------------------------------------------------------------
	const onSetRepresentativeNameInput = (value: string) => {
		setRepresentativeNameInput({ ...representativeNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, representativeNameInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, representativeNameInput))
	}, [representativeNameInput])

	//------------------------------------------------------------

	const isValidAddress = () => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		return coordinates.validate()
	}

	const onSetIsSameLocationInput = (value: string) => {
		setIsSameLocationInput({ ...isSameLocationInput, value })

		setDisableNextButton && setDisableNextButton(true)

		if (value == radioListYesNo[0].value) {
			if (!isValidAddress()) {
				setDisableNextButton && setDisableNextButton(false)
			}
			setTimeout(() => {
				setShowAdresseBlock(false)
			}, 100)
		} else {
			setTimeout(() => {
				setShowAdresseBlock(true)
			}, 100)
		}

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, isSameLocationInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, isSameLocationInput))
	}, [isSameLocationInput])
	//------------------------------------------------------------

	const setRequiredOwnerInfoInputs = (value: boolean) => {
		setOwnerFirstNameInput({ ...ownerFirstNameInput, required: value })
		setOwnerLastNameInput({ ...ownerLastNameInput, required: value })
		setOwnerEmailInput({ ...ownerEmailInput, required: value })
		setOwnerPhoneNumberInput({ ...ownerPhoneNumberInput, required: value })
		setFirstQuestion({ ...firstQuestion, required: value })
	}

	const onSetIsLocationOwnedInput = (value: string) => {
		setIsLocationOwnedInput({ ...isLocationOwnedInput, value })

		if (value == radioListYesNo[1].value) {
			setRequiredOwnerInfoInputs(true)
		} else {
			setRequiredOwnerInfoInputs(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isLocationOwnedInput.name,
						ownerFirstNameInput.name,
						ownerLastNameInput.name,
						ownerEmailInput.name,
						ownerPhoneNumberInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, isLocationOwnedInput))
	}, [isLocationOwnedInput])
	//------------------------------------------------------------

	const onSetOwnerFirstNameInput = (value: string) => {
		setOwnerFirstNameInput({ ...ownerFirstNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, ownerFirstNameInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, ownerFirstNameInput))
	}, [ownerFirstNameInput])
	//------------------------------------------------------------

	const onSetOwnerLastNameInput = (value: string) => {
		setOwnerLastNameInput({ ...ownerLastNameInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, ownerLastNameInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, ownerLastNameInput))
	}, [ownerLastNameInput])
	//------------------------------------------------------------

	const onSetOwnerEmailInput = (value: string) => {
		setOwnerEmailInput({ ...ownerEmailInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, ownerEmailInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, ownerEmailInput))
	}, [ownerEmailInput])
	//------------------------------------------------------------

	const onSetOwnerPhoneNumberInput = (value: string) => {
		setOwnerPhoneNumberInput({ ...ownerPhoneNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, ownerPhoneNumberInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, ownerPhoneNumberInput))
	}, [ownerPhoneNumberInput])
	//------------------------------------------------------------

	const onSetIsPropertyJointOwnershipInput = (value: string) => {
		setIsPropertyJointOwnershipInput({
			...isPropertyJointOwnershipInput,
			value
		})

		setSecondQuestion({ ...secondQuestion, required: value == 'true' })

		onFixError(
			errors.filter(
				(error) =>
					![isPropertyJointOwnershipInput.name, secondQuestion.name].includes(
						error
					)
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs!, isPropertyJointOwnershipInput)
		)
	}, [isPropertyJointOwnershipInput])
	//------------------------------------------------------------

	const onSetIsPropertyLocatedOnWaterfrontInput = (value: string) => {
		setIsPropertyLocatedOnWaterfrontInput({
			...isPropertyLocatedOnWaterfrontInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				isPropertyLocatedOnWaterfrontInput.name
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs!, isPropertyLocatedOnWaterfrontInput)
		)
	}, [isPropertyLocatedOnWaterfrontInput])
	//------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 3 ---------------------------
	// ----------------------------------------------------------------

	const onSetInstallationTypeInput = (value: string) => {
		setInstallationTypeInput({
			...installationTypeInput,
			value
		})

		if (POOL_SHAPE_REQUIRED_OPTIONS.includes(value)) {
			setDisableNextButton && setDisableNextButton(true)
			setIsSpaLitersConditionInput({
				...isSpaLitersConditionInput,
				required: false,
				value: ''
			})
			setPoolShapeInput({ ...poolShapeInput, required: true })
		} else {
			setIsSpaLitersConditionInput({
				...isSpaLitersConditionInput,
				required: true
			})
			setPoolShapeInput({ ...poolShapeInput, required: false })
		}

		if (
			value ==
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
					.firstOption &&
			whatToDoInput.value == radioListWhatToDoSwimmingPoolSpaPermit[0].value
		) {
			setPoolHeightInput({ ...poolHeightInput, required: true })
		} else {
			setPoolHeightInput({ ...poolHeightInput, required: false })
		}

		setProtectiveEnclosureTypeInput({
			...protectiveEnclosureTypeInput,
			value: ''
		})

		onFixError(
			errors.filter(
				(error) =>
					![
						installationTypeInput.name,
						poolShapeInput.name,
						isSpaLitersConditionInput.name,
						poolDiameterInput.name,
						poolHeightInput.name,
						poolWidthInput.name,
						poolLengthInput.name,
						expectedInstallDateInput.name,
						installationCostInput.name,
						isTreeCutRequiredInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, installationTypeInput))
	}, [installationTypeInput])
	//------------------------------------------------------------

	const onSetIsSpaLitersConditionInput = (value: string) => {
		setIsSpaLitersConditionInput({
			...isSpaLitersConditionInput,
			value
		})

		if (value == radioListYesNo[1].value) {
			setDisableNextButton && setDisableNextButton(false)
		} else {
			setDisableNextButton && setDisableNextButton(true)
		}

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, isSpaLitersConditionInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, isSpaLitersConditionInput))
	}, [isSpaLitersConditionInput])
	//------------------------------------------------------------

	const onSetPoolShapeInput = (value: string) => {
		setPoolShapeInput({
			...poolShapeInput,
			value
		})

		setPoolDiameterInput({
			...poolDiameterInput,
			required: false
		})
		setPoolWidthInput({
			...poolWidthInput,
			required: false
		})
		setPoolLengthInput({
			...poolLengthInput,
			required: false
		})

		if (
			whatToDoInput.value == radioListWhatToDoSwimmingPoolSpaPermit[0].value
		) {
			if (value == radioListPoolShape[0].value) {
				setPoolDiameterInput({
					...poolDiameterInput,
					required: true
				})
			} else {
				setPoolWidthInput({
					...poolWidthInput,
					label:
						value == radioListPoolShape[1].value
							? pageAssets?.request_form_width
							: pageAssets?.request_form_swimmingPoolSpaPermit_poolWidth,
					required: true
				})

				setPoolLengthInput({
					...poolLengthInput,
					label:
						value == radioListPoolShape[1].value
							? pageAssets?.request_form_length
							: pageAssets?.request_form_swimmingPoolSpaPermit_poolLength,
					required: true
				})
			}
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						poolShapeInput.name,
						poolDiameterInput.name,
						poolHeightInput.name,
						poolWidthInput.name,
						poolLengthInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, poolShapeInput))
	}, [poolShapeInput])
	//------------------------------------------------------------

	const onSetPoolDiameterInput = (value: string) => {
		setPoolDiameterInput({
			...poolDiameterInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, poolDiameterInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, poolDiameterInput))
	}, [poolDiameterInput])
	//------------------------------------------------------------

	const onSetPoolDiameterUnitInput = (value: string) => {
		setPoolDiameterUnitInput({
			...poolDiameterUnitInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, poolDiameterUnitInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, poolDiameterUnitInput))
	}, [poolDiameterUnitInput])
	//------------------------------------------------------------

	const onSetPoolHeightInput = (value: string) => {
		setPoolHeightInput({
			...poolHeightInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, poolHeightInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, poolHeightInput))
	}, [poolHeightInput])
	//------------------------------------------------------------

	const onSetPoolHeightUnitInput = (value: string) => {
		setPoolHeightUnitInput({
			...poolHeightUnitInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, poolHeightUnitInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, poolHeightUnitInput))
	}, [poolHeightUnitInput])
	//------------------------------------------------------------

	const onSetPoolWidthInput = (value: string) => {
		setPoolWidthInput({
			...poolWidthInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, poolWidthInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, poolWidthInput))
	}, [poolWidthInput])
	//------------------------------------------------------------

	const onSetPoolWidthUnitInput = (value: string) => {
		setPoolWidthUnitInput({
			...poolWidthUnitInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, poolWidthUnitInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, poolWidthUnitInput))
	}, [poolWidthUnitInput])
	//------------------------------------------------------------

	const onSetPoolLengthInput = (value: string) => {
		setPoolLengthInput({
			...poolLengthInput,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, poolLengthInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, poolLengthInput))
	}, [poolLengthInput])
	//------------------------------------------------------------

	const onSetPoolLengthUnitInput = (value: string) => {
		setPoolLengthUnitInput({
			...poolLengthUnitInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, poolLengthUnitInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, poolLengthUnitInput))
	}, [poolLengthUnitInput])
	//------------------------------------------------------------

	////////////////////////////////////////////////////////////////

	const onSetExpectedInstallDateInput = (value: string) => {
		setExpectedInstallDateInput({
			...expectedInstallDateInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, expectedInstallDateInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, expectedInstallDateInput))
	}, [expectedInstallDateInput])
	//------------------------------------------------------------

	const onSetInstallationCostInput = (value: string) => {
		setInstallationCostInput({
			...installationCostInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, installationCostInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, installationCostInput))
	}, [installationCostInput])
	//------------------------------------------------------------

	const onSetIsTreeCutRequiredInput = (value: string) => {
		setIsTreeCutRequiredInput({
			...isTreeCutRequiredInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, isTreeCutRequiredInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, isTreeCutRequiredInput))
	}, [isTreeCutRequiredInput])
	//------------------------------------------------------------

	////////////////////////////////////////////////////////////////

	//------------------------------------------------------------
	const onSetCompanyPoolNameInput = (value: string) => {
		setCompanyPoolNameInput({ ...companyPoolNameInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, companyPoolNameInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, companyPoolNameInput))
	}, [companyPoolNameInput])

	//------------------------------------------------------------
	const onSetCompanyAdresseInput = (value: string) => {
		setCompanyAdresseInput({ ...companyAdresseInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, companyAdresseInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, companyAdresseInput))
	}, [companyAdresseInput])

	//------------------------------------------------------------
	const onSetCompanyCityInput = (value: string) => {
		setCompanyCityInput({ ...companyCityInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, companyCityInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, companyCityInput))
	}, [companyCityInput])
	//------------------------------------------------------------
	const onSetCompanyPostalCodeInput = (value: string) => {
		setCompanyPostalCodeInput({
			...companyPostalCodeInput,
			value: value?.toUpperCase()
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, companyPostalCodeInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, companyPostalCodeInput))
	}, [companyPostalCodeInput])
	//------------------------------------------------------------
	const onSetCompanyEmailInput = (value: string) => {
		setCompanyEmailInput({ ...companyEmailInput, value })

		onFixError(UpdateRequestInputsErrorsArray(errors!, companyEmailInput.name))
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, companyEmailInput))
	}, [companyEmailInput])

	//------------------------------------------------------------
	const onSetCompanyPhoneNumberInput = (value: string) => {
		setCompanyPhoneNumberInput({ ...companyPhoneNumberInput, value })

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, companyPhoneNumberInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, companyPhoneNumberInput))
	}, [companyPhoneNumberInput])

	//------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 4 ---------------------------
	// ----------------------------------------------------------------

	// a factory for the OptionList depending on the InstallationType
	// source list is  protectiveEnclosureTypeList: RadioList[]
	// Si à la question 8 réponse = "Piscine hors-terre" afficher seulement les options 1, 2 et 3.
	// Si à la question 8 réponse = "Piscine creusé" ou "Piscine semi-creusé" afficher seulement les options 4 et 5.
	// Si à la question 8 réponse = "Bain à remous (spa)" afficher seulement les options 1, 2, 3, 4, et 5.
	const getProtectiveEnclosureTypeListByInstallationType = (value) => {
		switch (value) {
			case REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
				.firstOption:
				return [
					protectiveEnclosureTypeList[0],
					protectiveEnclosureTypeList[1],
					protectiveEnclosureTypeList[2],
					protectiveEnclosureTypeList[5]
				]

			case REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
				.secondOption:
			case REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
				.thirdOption:
				return [
					protectiveEnclosureTypeList[3],
					protectiveEnclosureTypeList[4],
					protectiveEnclosureTypeList[5]
				]

			case REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
				.fourthOption:
				return protectiveEnclosureTypeList

			default:
				return []
		}
	}

	const onSelectProtectiveEnclosureTypeInput = (value: string) => {
		setProtectiveEnclosureTypeInput({
			...protectiveEnclosureTypeInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, protectiveEnclosureTypeInput.name)
		)
	}

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs, protectiveEnclosureTypeInput))
	}, [protectiveEnclosureTypeInput])

	//------------------------------------------------------------
	const onSetProtectiveEnclosurePurchaseDateInput = (value: string) => {
		setProtectiveEnclosurePurchaseDateInput({
			...protectiveEnclosurePurchaseDateInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				protectiveEnclosurePurchaseDateInput.name
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs!, protectiveEnclosurePurchaseDateInput)
		)
	}, [protectiveEnclosurePurchaseDateInput])
	//------------------------------------------------------------

	const onSetIsProtectiveEnclosureAlreadyInstalledInput = (value) => {
		setIsProtectiveEnclosureAlreadyInstalledInput({
			...isProtectiveEnclosureAlreadyInstalledInput,
			value: `${value}`
		})

		if (value == true) {
			setProtectiveEnclosurePurchaseDateInput({
				...protectiveEnclosurePurchaseDateInput,
				required: false,
				value: ''
			})
		} else {
			setProtectiveEnclosurePurchaseDateInput({
				...protectiveEnclosurePurchaseDateInput,
				required: true
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isProtectiveEnclosureAlreadyInstalledInput.name,
						protectiveEnclosurePurchaseDateInput.name
					].includes(error)
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(
				inputs!,
				isProtectiveEnclosureAlreadyInstalledInput
			)
		)
	}, [isProtectiveEnclosureAlreadyInstalledInput])

	const onSetIsProtectiveEnclosureHasWindowsInput = (value: string) => {
		setIsProtectiveEnclosureHasWindowsInput({
			...isProtectiveEnclosureHasWindowsInput,
			value
		})

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				isProtectiveEnclosureHasWindowsInput.name
			)
		)
	}

	useEffect(() => {
		onSetInputs(
			UpdateRequestInputsArray(inputs!, isProtectiveEnclosureHasWindowsInput)
		)
	}, [isProtectiveEnclosureHasWindowsInput])
	//------------------------------------------------------------

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, dummyOptionalInput))
	}, [dummyOptionalInput])

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// DOCUMENTS
	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		setFirstQuestion({
			...firstQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
	}

	const onSelectFileSecondQuestion = (files: Set<File>) => {
		setSecondQuestion({
			...secondQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, secondQuestion.name))
	}

	const onSelectFileThirdQuestion = (files: Set<File>) => {
		setThirdQuestion({
			...thirdQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, thirdQuestion.name))
	}

	const onSelectFileFourthQuestion = (files: Set<File>) => {
		setFourthQuestion({
			...fourthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, fourthQuestion.name))
	}

	const onSelectFileFifthQuestion = (files: Set<File>) => {
		setFifthQuestion({
			...fifthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, fifthQuestion.name))
	}

	const onSelectFileSixthQuestion = (files: Set<File>) => {
		setSixthQuestion({
			...sixthQuestion,
			files
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, sixthQuestion.name))
	}

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthQuestion))
	}, [fourthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthQuestion))
	}, [fifthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixthQuestion))
	}, [sixthQuestion])

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		if (!!answers.firstInput && firstQuestion.files.size == 0) {
			getFilesFromStore('firstInput', answers).then((result: Set<File>) => {
				setFirstQuestion({
					...firstQuestion,
					files: result
				})
			})
		}
		// secondInput input
		if (!!answers.secondInput && secondQuestion.files.size == 0) {
			getFilesFromStore('secondInput', answers).then((result: Set<File>) => {
				setSecondQuestion({
					...secondQuestion,
					files: result
				})
			})
		}
		// thirdInput input
		if (!!answers.thirdInput && thirdQuestion.files.size == 0) {
			getFilesFromStore('thirdInput', answers).then((result: Set<File>) => {
				setThirdQuestion({
					...thirdQuestion,
					files: result
				})
			})
		}
		// fouthInput input
		if (!!answers.fourthInput && fourthQuestion.files.size == 0) {
			getFilesFromStore('fourthInput', answers).then((result: Set<File>) => {
				setFourthQuestion({
					...fourthQuestion,
					files: result
				})
			})
		}
		// fifthInput input
		if (!!answers.fifthInput && fifthQuestion.files.size == 0) {
			getFilesFromStore('fifthInput', answers).then((result: Set<File>) => {
				setFifthQuestion({
					...fifthQuestion,
					files: result
				})
			})
		}

		// sixthQuestion input
		if (!!answers.sixthInput && sixthQuestion.files.size == 0) {
			getFilesFromStore('sixthInput', answers).then((result: Set<File>) => {
				setSixthQuestion({
					...sixthQuestion,
					files: result
				})
			})
		}
	}, [])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: true,
			details_4: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})

		setStepsList &&
			setStepsList([
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step1),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step2),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step3),
					subSteps: [
						pageAssets?.request_form_type_de_demandes,
						pageAssets?.request_form_request_location,
						pageAssets?.request_form_swimmingPoolSpaPermit_thirdSubStep_title_substep,
						pageAssets?.request_form_swimmingPoolSpaPermit_fourthSubStep_title,
						pageAssets?.request_form_required_documents
					]
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step4),
					subSteps: []
				},
				{
					title: formatStrapiText(pageAssets?.page_requestInfo_step5),
					subSteps: []
				}
			])
	}, [])

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const onKeyPressNumberInput = (event) => {
		const charCode = event.which ? event.which : event.keyCode
		const char = String.fromCharCode(charCode)
		const value = event.target.value + char

		// Autoriser uniquement les chiffres et un seul point décimal
		// Explication de l'expression régulière
		// ^(?!0{2,}): L'expression ?!0{2,} est un "negative lookahead" qui empêche la chaîne de commencer par deux ou plusieurs zéros.
		// \d*: Zéro ou plusieurs chiffres.
		// \.?: Une virgule optionnelle.
		// \d{0,2}$: Zéro à deux chiffres après la virgule.
		//              ( ^_^ )
		if (!/^(?!0{2,})\d*\.?\d{0,2}$/.test(value)) {
			event.preventDefault()
		}
	}

	const renderMeasurementInput = (
		measurementInput,
		measurementInputOnChange,
		unitInput,
		unitInputOnchange
	) => {
		return (
			<div className={classes.subContent}>
				<TextInput
					id={measurementInput.name}
					type="number"
					label={measurementInput.label}
					value={measurementInput.value ?? ''}
					onChange={measurementInputOnChange}
					hasError={errors?.includes(measurementInput.name)}
					customErrorText={
						measurementInput.value !== ''
							? 'Veuillez entrer un nombre valide.'
							: undefined
					}
					onKeyPress={onKeyPressNumberInput}
					min="0"
					required={measurementInput.required}
				/>
				<RadioInput
					name={unitInput.name}
					label={''}
					value={unitInput.value ?? ''}
					list={radioListMeasurementUnit}
					direction={Direction.horizontal}
					hasError={errors!.includes(unitInput.name)}
					onChange={unitInputOnchange}
					hasBackgroundWrapper={true}
				/>
			</div>
		)
	}

	if (isSummaryStep) {
		return (
			<section
				className={pageUtils.classes.noMargin}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_type_de_demandes}
							</h4>
						</div>

						{/* Question 1 */}
						<div>
							<strong>{whatToDoInput.label}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								<Text
									content={getLabelFromList(
										radioListWhatToDoSwimmingPoolSpaPermit,
										answers?.whatToDo
									)}
								/>
							</span>
						</div>

						{/* Question 2 */}
						<div>
							<strong>{requestAsInput.label}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(radioListRequestAs, answers?.requestAs)}
							</span>
						</div>

						{answers?.requestAs?.toString() ==
							REQUEST_CONFIGS_KEYS.requestAs.entreprise && (
							<>
								<div>
									<strong>{companyNameInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.companyName}
									</span>
								</div>
								<div>
									<strong>{representativeNameInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.representativeName}
									</span>
								</div>
							</>
						)}
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_request_location}
							</h4>
						</div>
						{/* Question 3 */}
						<div>
							<strong>{isSameLocationInput.label}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									radioListYesNo,
									answers?.sameAddressAsApplicant
								)}
							</span>
						</div>

						{/* Question 4 */}
						{answers?.sameAddressAsApplicant?.toString() ==
							radioListYesNo[1].value && (
							<>
								<div>
									<strong>{pageAssets?.request_form_location}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.addressField}
										<br />
										{answers?.addressLotNumber
											? pageAssets?.label_cadastral_number +
											  ': ' +
											  answers?.addressLotNumber
											: ''}
									</span>
								</div>
							</>
						)}

						{/* Question 5 */}
						<div>
							<strong>{isLocationOwnedInput.label}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(radioListYesNo, answers?.isLocationOwned)}
							</span>
						</div>

						{/* ****** Owner inputs ****** */}
						{answers?.isLocationOwned?.toString() ==
							radioListYesNo[1].value && (
							<>
								<div>
									<strong>{ownerFirstNameInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.ownerFirstName}
									</span>
								</div>
								<div>
									<strong>{ownerLastNameInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.ownerLastName}
									</span>
								</div>
								<div>
									<strong>{ownerEmailInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.ownerEmail}
									</span>
								</div>
								<div>
									<strong>{ownerPhoneNumberInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.ownerPhoneNumber}
									</span>
								</div>

								<RequestFiles
									subtitle={firstQuestion.label}
									name={firstQuestion.name}
									required={firstQuestion.required}
									files={firstQuestion.files}
									hasError={errors.includes(firstQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFirstQuestion}
									maxFiles={firstQuestion.numberFilesRequired}
									isSummary
								>
									<></>
								</RequestFiles>
								<br />
							</>
						)}

						{/* Question 6 */}
						<div>
							<strong>{isPropertyJointOwnershipInput.label}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									radioListYesNo,
									answers?.isPropertyJointOwnership
								)}
							</span>
						</div>

						{/* ****** Owner inputs ****** */}
						{answers?.isLocationOwned?.toString() ==
							radioListYesNo[1].value && (
							<>
								<RequestFiles
									subtitle={secondQuestion.label}
									name={secondQuestion.name}
									required={secondQuestion.required}
									files={secondQuestion.files}
									hasError={errors.includes(secondQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileSecondQuestion}
									maxFiles={secondQuestion.numberFilesRequired}
									isSummary
								>
									<></>
								</RequestFiles>
								<br />
							</>
						)}

						{/* Question 7 */}
						<div>
							<strong>{isPropertyLocatedOnWaterfrontInput.label}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(
									radioListYesNo,
									answers?.isPropertyLocatedOnWaterfront
								)}
							</span>
						</div>
					</>
				)}

				{subSteps?.details_3 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_swimmingPoolSpaPermit_thirdSubStep_title
								}
							</h4>
						</div>

						<div>
							{/* Question 8 */}
							<div>
								<strong>{installationTypeInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{getLabelFromList(
										radioListInstallationType,
										answers?.installationType
									)}
								</span>
							</div>

							{answers?.installationType?.toString() ==
								REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
									.fourthOption && (
								<>
									<div>
										<strong>{isSpaLitersConditionInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{getLabelFromList(
												radioListYesNo,
												answers?.isSpaLitersCondition
											)}
										</span>
									</div>
								</>
							)}

							{POOL_SHAPE_REQUIRED_OPTIONS.includes(
								answers?.installationType?.toString() || ''
							) && (
								<>
									<div>
										<strong>{poolShapeInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{getLabelFromList(radioListPoolShape, answers?.poolShape)}
										</span>
									</div>
									{answers?.whatToDo?.toString() ==
										radioListWhatToDoSwimmingPoolSpaPermit[0].value && (
										<div className={classes.detailscontainer}>
											<div>
												{/* Question 10.1 */}
												{/* Diamètre IF shape Ronde */}
												{answers?.poolShape?.toString() ==
													radioListPoolShape[0].value && (
													<div>
														<strong>{poolDiameterInput.label}</strong>
														<br />
														<span className={pageUtils.classes.answer}>
															{answers?.poolDiameter}
															{` `}
															{getLabelFromList(
																radioListMeasurementUnit,
																answers?.poolDiameterUnit
															)}
														</span>
													</div>
												)}

												{[
													radioListPoolShape[1].value,
													radioListPoolShape[2].value
												].includes(answers?.poolShape?.toString() || '') && (
													<>
														{/* Question 10.3 */}
														<div>
															<strong>{poolWidthInput.label}</strong>
															<br />
															<span className={pageUtils.classes.answer}>
																{answers?.poolWidth}
																{` `}
																{getLabelFromList(
																	radioListMeasurementUnit,
																	answers?.poolWidthUnit
																)}
															</span>
														</div>

														{/* Question 10.4 */}
														<div>
															<strong>{poolLengthInput.label}</strong>
															<br />
															<span className={pageUtils.classes.answer}>
																{answers?.poolLength}
																{` `}
																{getLabelFromList(
																	radioListMeasurementUnit,
																	answers?.poolLengthUnit
																)}
															</span>
														</div>
													</>
												)}

												{answers?.installationType?.toString() ==
													REQUEST_CONFIGS_KEYS
														.installationTypeSwimmingPoolSpaPermit
														.firstOption && (
													<div>
														<strong>{poolHeightInput.label}</strong>
														<br />
														<span className={pageUtils.classes.answer}>
															{answers?.poolHeight}
															{` `}
															{getLabelFromList(
																radioListMeasurementUnit,
																answers?.poolHeightUnit
															)}
														</span>
													</div>
												)}
											</div>

											<img
												src={getPoolShapeImage(answers?.poolShape?.toString())}
											/>
										</div>
									)}
								</>
							)}
						</div>

						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_swimmingPoolSpaPermit_installationAndWorks
							}
						</h5>
						{answers?.whatToDo?.toString() ==
							radioListWhatToDoSwimmingPoolSpaPermit[0].value && (
							<>
								{/* Question 11 */}
								<div>
									<strong>{expectedInstallDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{pageUtils.getLocaleDate(answers?.expectedInstallDate!)}
									</span>
								</div>
								{/* Question 12 */}
								<div>
									<strong>{installationCostInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.installationCost} $
									</span>
								</div>
							</>
						)}

						{/* Question 13 */}
						<div>
							<strong>{isTreeCutRequiredInput.label}</strong>
							<br />
							<span className={pageUtils.classes.answer}>
								{getLabelFromList(radioListYesNo, answers?.isTreeCutRequired)}
							</span>
						</div>
						{answers?.whatToDo?.toString() ==
							radioListWhatToDoSwimmingPoolSpaPermit[0].value && (
							<>
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_swimmingPoolSpaPermit_poolCompanySeller_title
									}
								</h5>
								{/* Question 14 */}
								<div>
									<strong>{companyPoolNameInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.companyPoolName}
									</span>
								</div>
								{/* Question 15 */}
								<div>
									<strong>{companyAdresseInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.companyAdresse}
									</span>
								</div>
								{/* Question 16 */}
								<div>
									<strong>{companyCityInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.companyCity}
									</span>
								</div>
								{/* Question 17 */}
								<div>
									<strong>{companyPostalCodeInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.companyPostalCode}
									</span>
								</div>
								{/* Question 18 */}
								<div>
									<strong>{companyEmailInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.companyEmail}
									</span>
								</div>
								{/* Question 19 */}
								<div>
									<strong>{companyPhoneNumberInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.companyPhoneNumber}
									</span>
								</div>{' '}
							</>
						)}
					</>
				)}
				{subSteps?.details_4 && (
					<>
						<div>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_swimmingPoolSpaPermit_fourthSubStep_title
								}
							</h4>
							{/* Question 20 */}
							<div>
								<strong>{protectiveEnclosureTypeInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{getLabelFromList(
										protectiveEnclosureTypeList,
										answers?.protectiveEnclosureType
									)}
								</span>

								{/* Question 21 */}
								<div>
									<strong>{protectiveEnclosurePurchaseDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.isProtectiveEnclosureAlreadyInstalled?.toString() ==
										'true'
											? isProtectiveEnclosureAlreadyInstalledInput.label
											: pageUtils.getLocaleDate(
													answers?.protectiveEnclosurePurchaseDate!
											  )}
									</span>
								</div>

								{/* Question 22 */}
								<div>
									<strong>{isProtectiveEnclosureHasWindowsInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{getLabelFromList(
											radioListYesNo,
											answers?.isProtectiveEnclosureHasWindows
										)}
									</span>
								</div>
							</div>
						</div>
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<div style={{ marginTop: '40px' }}>
							<a
								style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() =>
									backToForm(
										steps?.form!,
										REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
									)
								}
							>
								{pageAssets?.label_modify}
							</a>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_required_documents}
							</h4>
						</div>

						<RequestFiles
							subtitle={thirdQuestion.label}
							name={thirdQuestion.name}
							required={thirdQuestion.required}
							files={thirdQuestion.files}
							hasError={errors.includes(thirdQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileThirdQuestion}
							maxFiles={thirdQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={fourthQuestion.label}
							name={fourthQuestion.name}
							required={fourthQuestion.required}
							files={fourthQuestion.files}
							hasError={errors.includes(fourthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFourthQuestion}
							maxFiles={fourthQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						<RequestFiles
							subtitle={fifthQuestion.label}
							name={fifthQuestion.name}
							required={fifthQuestion.required}
							files={fifthQuestion.files}
							hasError={errors.includes(fifthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFifthQuestion}
							maxFiles={fifthQuestion.numberFilesRequired}
							isSummary
						>
							<></>
						</RequestFiles>

						{sixthQuestion.files.size > 0 && (
							<RequestFiles
								subtitle={sixthQuestion.label}
								name={sixthQuestion.name}
								required={sixthQuestion.required}
								files={sixthQuestion.files}
								hasError={errors.includes(sixthQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileSixthQuestion}
								maxFiles={sixthQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>
						)}
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_de_demandes}
						</h4>
						{/* Question 1 */}
						<RadioInput
							name={whatToDoInput.name}
							label={whatToDoInput.label}
							value={whatToDoInput.value}
							list={radioListWhatToDoSwimmingPoolSpaPermit}
							direction={Direction.vertical}
							hasError={errors!.includes(whatToDoInput.name)}
							onChange={onSetWhatToDoInput}
							ref={whatToDoInput.ref as RefObject<HTMLInputElement>}
							required={whatToDoInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 2 */}
						<RadioInput
							name={requestAsInput.name}
							label={requestAsInput.label}
							value={requestAsInput.value}
							list={radioListRequestAs}
							direction={Direction.vertical}
							hasError={errors!.includes(requestAsInput.name)}
							onChange={onSetRequestAsInput}
							ref={requestAsInput.ref as RefObject<HTMLInputElement>}
							required={requestAsInput.required}
							hasBackgroundWrapper={true}
						/>

						{requestAsInput.value ==
							REQUEST_CONFIGS_KEYS.requestAs.entreprise && (
							<>
								<TextInput
									id={companyNameInput.name}
									label={companyNameInput.label}
									value={companyNameInput.value}
									onChange={onSetCompanyNameInput}
									hasError={errors?.includes(companyNameInput.name)}
									required={companyNameInput.required}
									ref={companyNameInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>

								<TextInput
									id={representativeNameInput.name}
									label={representativeNameInput.label}
									value={representativeNameInput.value}
									onChange={onSetRepresentativeNameInput}
									hasError={errors?.includes(representativeNameInput.name)}
									required={representativeNameInput.required}
									ref={
										representativeNameInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_request_location}
						</h4>

						<div className={classes.adresseBlock}>
							<strong>{pageAssets?.label_address}</strong>
							<br />
							{authUser?.profile?.address.address}
							<br />
							{authUser?.profile?.address.city}
							{authUser?.profile?.address.state
								? ` (${authUser?.profile?.address.state}) `
								: ' '}
							{authUser?.profile?.address.postalCode}
							<br />
							{authUser?.profile?.address.apartment
								? `${formatStrapiText(pageAssets?.label_apartment_office)} ${
										authUser?.profile?.address.apartment
								  }`
								: ''}
						</div>

						{/* Question 3 */}
						<RadioInput
							name={isSameLocationInput.name}
							label={isSameLocationInput.label}
							value={isSameLocationInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isSameLocationInput.name)}
							onChange={onSetIsSameLocationInput}
							ref={isSameLocationInput.ref as RefObject<HTMLInputElement>}
							required={isSameLocationInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* Question 4 */}
						{showAdresseBlock == true && (
							// @todo garder le N Lot affiché dans le input après un retour
							// +++ bloquer le passage si N Lot est invalide ( ca cause un crash du site )
							<>
								<AddressInfoSection
									label={pageAssets?.request_form_location}
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										isSameLocationInput.value == radioListYesNo[1].value
									}
									showButtonUserLocation={false}
									displayAddressDetails={false}
									requiredFields={[
										'addressOption',
										'addressField',
										'addressLotNumber',
										'addressRegistrationNumber'
										// 'addressAppartment'
									]}
									subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2}
									addressOptions={radioListAddressOption}
								/>
								<br />
							</>
						)}

						{isSameLocationInput.value == radioListYesNo[0].value &&
							!isValidAddress() && (
								<Notification
									text={
										pageAssets?.request_form_isAddressOutsideLavalMessageShow_notification_label
									}
									type="warning"
									hasHtml
								/>
							)}

						{/* Question 5 */}
						<RadioInput
							name={isLocationOwnedInput.name}
							label={isLocationOwnedInput.label}
							value={isLocationOwnedInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isLocationOwnedInput.name)}
							onChange={onSetIsLocationOwnedInput}
							ref={isLocationOwnedInput.ref as RefObject<HTMLInputElement>}
							required={isLocationOwnedInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* ****** Owner inputs ****** */}
						{isLocationOwnedInput.value == radioListYesNo[1].value && (
							<>
								<Notification
									text={
										pageAssets?.request_form_ownerPowerOfAttorney_notification
									}
									type="info"
									hasHtml
								/>
								<div
									className={joinClasses([
										pageUtils.classes.fieldsContainer,
										classes.greyBackgroundBlock
									])}
								>
									<TextInput
										id={ownerFirstNameInput.name}
										label={ownerFirstNameInput.label}
										value={ownerFirstNameInput.value}
										onChange={onSetOwnerFirstNameInput}
										hasError={errors.includes(ownerFirstNameInput.name)}
										required={ownerFirstNameInput.required}
										ref={ownerFirstNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>
									<TextInput
										id={ownerLastNameInput.name}
										label={ownerLastNameInput.label}
										value={ownerLastNameInput.value}
										onChange={onSetOwnerLastNameInput}
										hasError={errors.includes(ownerLastNameInput.name)}
										required={ownerLastNameInput.required}
										ref={ownerLastNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>

									<TextInput
										id={ownerEmailInput.name}
										label={ownerEmailInput.label}
										value={ownerEmailInput.value}
										onChange={onSetOwnerEmailInput}
										hasError={errors?.includes(ownerEmailInput.name)}
										type="email"
										onSetError={onFixError}
										schema={formUtils.MASKS.email}
										schemaErrorText={pageAssets?.label_emailErrorText}
										required={ownerEmailInput.required}
										ref={ownerEmailInput.ref as RefObject<HTMLInputElement>}
										maxCharacters={100}
									/>
									<MaskedTextInput
										id={ownerPhoneNumberInput.name}
										label={ownerPhoneNumberInput.label}
										value={ownerPhoneNumberInput.value}
										onChange={onSetOwnerPhoneNumberInput}
										hasError={errors?.includes(ownerPhoneNumberInput.name)}
										type="tel"
										min={10}
										max={14}
										mask={formUtils.MASKS.phoneNumber}
										placeholder={formUtils.MASKS.phoneNumber}
										errors={errors ? errors : []}
										setError={onFixError}
										schema={PHONE_NUMBER_REGEX}
										required={ownerPhoneNumberInput.required}
										ref={
											ownerPhoneNumberInput.ref as RefObject<HTMLInputElement>
										}
									/>
								</div>

								<RequestFiles
									subtitle={firstQuestion.label}
									name={firstQuestion.name}
									required
									files={firstQuestion.files}
									hasError={errors.includes(firstQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFirstQuestion}
									subSteps={subSteps}
									maxFiles={firstQuestion.numberMaxFiles}
									isNewDesign
								></RequestFiles>
								<br />
							</>
						)}

						{/* Question 6 */}
						<RadioInput
							name={isPropertyJointOwnershipInput.name}
							label={isPropertyJointOwnershipInput.label}
							value={isPropertyJointOwnershipInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isPropertyJointOwnershipInput.name)}
							onChange={onSetIsPropertyJointOwnershipInput}
							ref={
								isPropertyJointOwnershipInput.ref as RefObject<HTMLInputElement>
							}
							required={isPropertyJointOwnershipInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{isPropertyJointOwnershipInput.value == radioListYesNo[0].value && (
							<>
								<Notification
									text={
										pageAssets?.request_form_swimmingPoolSpaPermit_admin_permission_notification
									}
									type="info"
									hasHtml
								/>

								<RequestFiles
									subtitle={secondQuestion.label}
									name={secondQuestion.name}
									required
									files={secondQuestion.files}
									hasError={errors.includes(secondQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileSecondQuestion}
									subSteps={subSteps}
									maxFiles={secondQuestion.numberMaxFiles}
									isNewDesign
								></RequestFiles>
								<br />
							</>
						)}

						{/* Question 7 */}
						<RadioInput
							name={isPropertyLocatedOnWaterfrontInput.name}
							label={isPropertyLocatedOnWaterfrontInput.label}
							value={isPropertyLocatedOnWaterfrontInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(
								isPropertyLocatedOnWaterfrontInput.name
							)}
							onChange={onSetIsPropertyLocatedOnWaterfrontInput}
							ref={
								isPropertyLocatedOnWaterfrontInput.ref as RefObject<HTMLInputElement>
							}
							required={isPropertyLocatedOnWaterfrontInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{isPropertyLocatedOnWaterfrontInput.value ==
							radioListYesNo[0].value && (
							<>
								<Notification
									text={
										pageAssets?.request_form_swimmingPoolSpaPermit_Additional_info_may_be_requested_notification
									}
									type="info"
									hasHtml
								/>
							</>
						)}
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{
								pageAssets?.request_form_swimmingPoolSpaPermit_thirdSubStep_title
							}
						</h4>

						{/* Question 8 */}
						<SelectInput
							id={installationTypeInput.name}
							label={installationTypeInput.label}
							value={installationTypeInput.value}
							list={radioListInstallationType}
							hasError={errors!.includes(installationTypeInput.name)}
							onChange={onSetInstallationTypeInput}
							ref={installationTypeInput.ref as RefObject<HTMLSelectElement>}
							required={installationTypeInput.required}
						/>

						{installationTypeInput.value ==
							REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
								.fourthOption && (
							<>
								{/* Question 9 */}
								<RadioInput
									name={isSpaLitersConditionInput.name}
									label={isSpaLitersConditionInput.label}
									value={isSpaLitersConditionInput.value}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors!.includes(isSpaLitersConditionInput.name)}
									onChange={onSetIsSpaLitersConditionInput}
									ref={
										isSpaLitersConditionInput.ref as RefObject<HTMLInputElement>
									}
									required={isSpaLitersConditionInput.required}
									hasBackgroundWrapper={true}
								/>

								{isSpaLitersConditionInput.value == radioListYesNo[0].value && (
									<Notification
										text={
											pageAssets?.request_form_swimmingPoolSpaPermit_isSpaLitersCondition_info_notification
										}
										type="info"
										hasHtml
									/>
								)}

								{isSpaLitersConditionInput.value == radioListYesNo[1].value && (
									<Notification
										text={
											pageAssets?.request_form_swimmingPoolSpaPermit_isSpaLitersCondition_warning_notification
										}
										type="error"
										hasHtml
									/>
								)}
							</>
						)}

						{POOL_SHAPE_REQUIRED_OPTIONS.includes(
							installationTypeInput.value
						) && (
							<>
								{/* Question 10 */}
								<RadioInput
									name={poolShapeInput.name}
									label={poolShapeInput.label}
									value={poolShapeInput.value}
									list={radioListPoolShape}
									direction={Direction.vertical}
									hasError={errors!.includes(poolShapeInput.name)}
									onChange={onSetPoolShapeInput}
									ref={poolShapeInput.ref as RefObject<HTMLInputElement>}
									required={poolShapeInput.required}
									hasBackgroundWrapper={true}
								/>

								{poolShapeInput.value &&
									whatToDoInput.value ==
										radioListWhatToDoSwimmingPoolSpaPermit[0].value && (
										<>
											<div className={classes.detailscontainer}>
												<>
													<div>
														{/* Question 10.1 */}
														{/* Diamètre IF shape Ronde */}
														{poolShapeInput.value ==
															radioListPoolShape[0].value &&
															renderMeasurementInput(
																poolDiameterInput,
																onSetPoolDiameterInput,
																poolDiameterUnitInput,
																onSetPoolDiameterUnitInput
															)}

														{[
															radioListPoolShape[1].value,
															radioListPoolShape[2].value
														].includes(poolShapeInput.value) && (
															<>
																{/* Question 10.3 */}
																{renderMeasurementInput(
																	poolWidthInput,
																	onSetPoolWidthInput,
																	poolWidthUnitInput,
																	onSetPoolWidthUnitInput
																)}

																{/* Question 10.4 */}
																{renderMeasurementInput(
																	poolLengthInput,
																	onSetPoolLengthInput,
																	poolLengthUnitInput,
																	onSetPoolLengthUnitInput
																)}
															</>
														)}

														{/* Question 10.2 */}
														{/* Hauteur IF installType Non creusée */}
														{installationTypeInput.value ==
															REQUEST_CONFIGS_KEYS
																.installationTypeSwimmingPoolSpaPermit
																.firstOption &&
															renderMeasurementInput(
																poolHeightInput,
																onSetPoolHeightInput,
																poolHeightUnitInput,
																onSetPoolHeightUnitInput
															)}
													</div>
												</>
												<>
													<img src={getPoolShapeImage(poolShapeInput.value)} />
												</>
											</div>
										</>
									)}
							</>
						)}

						{installationTypeInput.value &&
							!(
								// Ne pas afficher si isSpaLitersConditionInput == false
								(
									installationTypeInput.value ==
										REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
											.fourthOption &&
									isSpaLitersConditionInput.value == 'false'
								)
							) && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_swimmingPoolSpaPermit_installationAndWorks
										}
									</h5>

									{whatToDoInput.value ==
										radioListWhatToDoSwimmingPoolSpaPermit[0].value && (
										<>
											{/* Question 11 */}
											<DateFilterInput
												id={expectedInstallDateInput.name}
												label={expectedInstallDateInput.label}
												value={expectedInstallDateInput.value}
												onChange={onSetExpectedInstallDateInput}
												hasError={errors.includes(
													expectedInstallDateInput.name
												)}
												classWrapper={classes.inputContainer}
												required={expectedInstallDateInput.required}
												dateFormat="dd/MM/yyyy"
												minDate={new Date()}
											/>

											{/* Question 12 */}
											<TextInput
												type="number"
												id={installationCostInput.name}
												label={installationCostInput.label}
												value={installationCostInput.value}
												description={installationCostInput.description}
												onChange={onSetInstallationCostInput}
												hasError={errors.includes(installationCostInput.name)}
												required
												ref={
													installationCostInput.ref as RefObject<HTMLInputElement>
												}
												classWrapper={classes.inputContainer}
												// onBlur={() => {
												// 	setSubscriptionAmountWarning(
												// 		installationCostInput.value !== '' &&
												// 			Number(installationCostInput.value) < 250
												// 	)
												// }}
												isMoneyInput
											/>
										</>
									)}

									{/* Question 13 */}
									<RadioInput
										name={isTreeCutRequiredInput.name}
										label={isTreeCutRequiredInput.label}
										value={isTreeCutRequiredInput.value}
										list={radioListYesNo}
										direction={Direction.vertical}
										hasError={errors!.includes(isTreeCutRequiredInput.name)}
										onChange={onSetIsTreeCutRequiredInput}
										ref={
											isTreeCutRequiredInput.ref as RefObject<HTMLInputElement>
										}
										required={isTreeCutRequiredInput.required}
										hasBackgroundWrapper={true}
										description={isTreeCutRequiredInput.description}
									/>
								</>
							)}

						{installationTypeInput.value &&
							whatToDoInput.value ==
								radioListWhatToDoSwimmingPoolSpaPermit[0].value &&
							!(
								// Ne pas afficher si isSpaLitersConditionInput == false
								(
									installationTypeInput.value ==
										REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
											.fourthOption &&
									isSpaLitersConditionInput.value == 'false'
								)
							) && (
								<>
									<h5 className={pageUtils.classes.h5}>
										{
											pageAssets?.request_form_swimmingPoolSpaPermit_poolCompanySeller_title
										}
									</h5>

									{/* Question 14 */}
									<TextInput
										id={companyPoolNameInput.name}
										label={companyPoolNameInput.label}
										value={companyPoolNameInput.value}
										onChange={onSetCompanyPoolNameInput}
										hasError={errors?.includes(companyPoolNameInput.name)}
										required={companyPoolNameInput.required}
										ref={
											companyPoolNameInput.ref as RefObject<HTMLInputElement>
										}
										maxCharacters={100}
									/>
									{/* Question 15 */}
									<TextInput
										id={companyAdresseInput.name}
										label={companyAdresseInput.label}
										value={companyAdresseInput.value}
										onChange={onSetCompanyAdresseInput}
										hasError={errors?.includes(companyAdresseInput.name)}
										required={companyAdresseInput.required}
										ref={companyAdresseInput.ref as RefObject<HTMLInputElement>}
										maxCharacters={100}
									/>

									<div className={pageUtils.classes.fieldsContainer}>
										{/* Question 16 */}
										<TextInput
											id={companyCityInput.name}
											label={companyCityInput.label}
											value={companyCityInput.value}
											onChange={onSetCompanyCityInput}
											hasError={errors?.includes(companyCityInput.name)}
											required={companyCityInput.required}
											ref={companyCityInput.ref as RefObject<HTMLInputElement>}
										/>

										{/* <TextInput
										id={companyPostalCodeInput.name}
										label={companyPostalCodeInput.label}
										value={companyPostalCodeInput.value}
										onChange={onSetCompanyPostalCodeInput}
										hasError={errors?.includes(companyPostalCodeInput.name)}
										required={companyPostalCodeInput.required}
										ref={
											companyPostalCodeInput.ref as RefObject<HTMLInputElement>
										}
									/> */}

										{/* Question 17 */}
										<MaskedTextInput
											id={companyPostalCodeInput.name}
											label={companyPostalCodeInput.label}
											value={companyPostalCodeInput.value}
											onChange={onSetCompanyPostalCodeInput}
											hasError={errors?.includes(companyPostalCodeInput.name)}
											min={7}
											max={7}
											mask={formUtils.MASKS.postalCode}
											placeholder="A1A 1A1"
											errors={errors}
											setError={onFixError}
											schema={PHONE_NUMBER_REGEX}
											required={companyPostalCodeInput.required}
											ref={
												companyPostalCodeInput.ref as RefObject<HTMLInputElement>
											}
										/>

										{/* Question 18 */}
										<TextInput
											id={companyEmailInput.name}
											label={companyEmailInput.label}
											value={companyEmailInput.value}
											onChange={onSetCompanyEmailInput}
											hasError={errors?.includes(companyEmailInput.name)}
											type="email"
											onSetError={onFixError}
											schema={formUtils.MASKS.email}
											schemaErrorText={pageAssets.label_emailErrorText}
											required={companyEmailInput.required}
											ref={companyEmailInput.ref as RefObject<HTMLInputElement>}
										/>
										{/* Question 19 */}
										<MaskedTextInput
											id={companyPhoneNumberInput.name}
											label={companyPhoneNumberInput.label}
											value={companyPhoneNumberInput.value}
											onChange={onSetCompanyPhoneNumberInput}
											hasError={errors?.includes(companyPhoneNumberInput.name)}
											type="tel"
											min={10}
											max={14}
											mask={formUtils.MASKS.phoneNumber}
											placeholder={formUtils.MASKS.phoneNumber}
											errors={errors ? errors : []}
											setError={onFixError}
											schema={PHONE_NUMBER_REGEX}
											required={companyPhoneNumberInput.required}
											ref={
												companyPhoneNumberInput.ref as RefObject<HTMLInputElement>
											}
										/>
									</div>
								</>
							)}

						<br />
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{
								pageAssets?.request_form_swimmingPoolSpaPermit_fourthSubStep_title
							}
						</h4>

						<Text
							content={
								pageAssets?.request_form_swimmingPoolSpaPermit_fourthSubStep_description
							}
						/>

						<br />

						{/* Question 20 */}
						<RadioInput
							name={protectiveEnclosureTypeInput.name}
							label={protectiveEnclosureTypeInput.label}
							value={protectiveEnclosureTypeInput.value}
							list={getProtectiveEnclosureTypeListByInstallationType(
								installationTypeInput.value?.toString()
							)}
							direction={Direction.vertical}
							hasError={errors!.includes(protectiveEnclosureTypeInput.name)}
							onChange={onSelectProtectiveEnclosureTypeInput}
							ref={
								protectiveEnclosureTypeInput.ref as RefObject<HTMLInputElement>
							}
							required={protectiveEnclosureTypeInput.required}
							hasBackgroundWrapper={true}
							alternateDisplay={true}
						/>

						{/* Question 21 */}
						<DateFilterInput
							id={protectiveEnclosurePurchaseDateInput.name}
							label={protectiveEnclosurePurchaseDateInput.label}
							value={protectiveEnclosurePurchaseDateInput.value}
							onChange={onSetProtectiveEnclosurePurchaseDateInput}
							hasError={errors.includes(
								protectiveEnclosurePurchaseDateInput.name
							)}
							classWrapper={classes.inputContainer}
							required={protectiveEnclosurePurchaseDateInput.required}
							dateFormat="dd/MM/yyyy"
							disabled={
								isProtectiveEnclosureAlreadyInstalledInput.value.toString() ==
								'true'
							}
						/>

						{whatToDoInput.value ==
							radioListWhatToDoSwimmingPoolSpaPermit[0].value && (
							<div className={classes.checkbox2Container}>
								<span className={classes.checkboxWrapper}>
									<input
										onClick={(e: any) =>
											onSetIsProtectiveEnclosureAlreadyInstalledInput(
												e.target.checked
											)
										}
										value={isProtectiveEnclosureAlreadyInstalledInput.value}
										className={classes.checkbox2}
										type="checkbox"
										name={isProtectiveEnclosureAlreadyInstalledInput.name}
										id={isProtectiveEnclosureAlreadyInstalledInput.labelKey}
									/>
									<label
										className={classes.checkbox2Label}
										htmlFor={
											isProtectiveEnclosureAlreadyInstalledInput.labelKey
										}
									>
										{
											pageAssets[
												isProtectiveEnclosureAlreadyInstalledInput.labelKey
											]
										}
									</label>
								</span>
							</div>
						)}

						{/* Question 22 */}
						<RadioInput
							name={isProtectiveEnclosureHasWindowsInput.name}
							label={isProtectiveEnclosureHasWindowsInput.label}
							value={isProtectiveEnclosureHasWindowsInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(
								isProtectiveEnclosureHasWindowsInput.name
							)}
							onChange={onSetIsProtectiveEnclosureHasWindowsInput}
							ref={
								isProtectiveEnclosureHasWindowsInput.ref as RefObject<HTMLInputElement>
							}
							required={isProtectiveEnclosureHasWindowsInput.required}
							hasBackgroundWrapper={true}
						/>
					</section>
				</Collapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_required_documents}
						</h4>

						<RequestFiles
							subtitle={thirdQuestion.label}
							name={thirdQuestion.name}
							required
							files={thirdQuestion.files}
							hasError={errors.includes(thirdQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileThirdQuestion}
							subSteps={subSteps}
							maxFiles={thirdQuestion?.numberMaxFiles}
							description={thirdQuestion.description}
						></RequestFiles>

						<RequestFiles
							subtitle={fourthQuestion.label}
							name={fourthQuestion.name}
							required
							files={fourthQuestion.files}
							hasError={errors.includes(fourthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFourthQuestion}
							subSteps={subSteps}
							maxFiles={fourthQuestion?.numberMaxFiles}
							description={fourthQuestion.description}
						></RequestFiles>

						<RequestFiles
							subtitle={fifthQuestion.label}
							name={fifthQuestion.name}
							required={fifthQuestion.required}
							files={fifthQuestion.files}
							hasError={errors.includes(fifthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFifthQuestion}
							subSteps={subSteps}
							maxFiles={fifthQuestion?.numberMaxFiles}
							description={fifthQuestion.description}
						></RequestFiles>

						<RequestFiles
							subtitle={sixthQuestion.label}
							name={sixthQuestion.name}
							required={sixthQuestion.required}
							files={sixthQuestion.files}
							hasError={errors.includes(sixthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileSixthQuestion}
							subSteps={subSteps}
							maxFiles={sixthQuestion?.numberMaxFiles}
							description={sixthQuestion.description}
						></RequestFiles>
					</section>
				</Collapse>
			)}
		</>
	)
}

export default SwimmingPoolSpaPermitForm
